/* Neu organisierte CSS-Datei für TicketManager mit einheitlichen Spaltenbreiten */
.ticket-manager {
  height: calc(100vh - 64px) !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ticket-container {
  height: 100% !important;
  overflow: hidden;
}

.ticket-details {
  height: 100% !important;
  overflow: hidden;
}

/* Split-View Styling */
.split-flex {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.gutter {
  background-color: rgba(81, 81, 81, 0.3);
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: col-resize;
}

/* DataGrid Haupt-Styling */
.MuiDataGrid-root {
  background-color: rgb(17, 24, 39) !important;
  color: rgb(209, 213, 219) !important;
  border-color: rgb(55, 65, 81) !important;
  height: 100% !important;
  border: none !important;
  font-size: 0.9rem !important;
  table-layout: fixed !important; /* Feste Tabellenbreite */
}

/* Kopfzeile der Tabelle */
.MuiDataGrid-columnHeaders {
  background-color: rgb(17, 24, 39) !important;
  color: rgb(209, 213, 219) !important;
  border-color: rgb(55, 65, 81) !important;
  border-bottom: 2px solid rgba(155, 155, 155, 0.7) !important;
}

.MuiDataGrid-columnHeader {
  background-color: rgb(17, 24, 39) !important;
  height: 45px !important;
  max-height: 45px !important;
  min-height: 45px !important;
  border-right: 1px solid rgba(81, 81, 81, 0.3) !important;
  text-align: left !important;
  padding: 4px 8px !important;
}

/* Vollständig sichtbare Spaltenüberschriften */
.MuiDataGrid-columnHeaderTitle {
  color: rgb(209, 213, 219) !important;
  font-size: 0.95rem !important;
  font-weight: 600 !important;
  text-align: left !important;
  overflow: visible !important; /* Stellt sicher, dass Überschriften vollständig sichtbar sind */
  text-overflow: clip !important;
  white-space: normal !important;
  line-height: 1.2 !important;
}

/* Zellenformatierung */
.MuiDataGrid-cell {
  padding: 4px 8px !important;
  border-right: 1px solid rgba(81, 81, 81, 0.3) !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  border-color: rgb(55, 65, 81) !important;
  color: rgb(209, 213, 219) !important;
}

/* Alle Spalten haben einheitliche Breite */
.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
  min-width: 120px !important;
  width: 120px !important;
  max-width: 120px !important;
  padding: 4px 8px !important;
  border-right: 1px solid rgba(81, 81, 81, 0.3) !important; /* Klare Spaltentrennung */
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  border-color: rgb(55, 65, 81) !important;
  color: rgb(209, 213, 219) !important;
}

/* Titel-Spalte ist breiter */
.MuiDataGrid-columnHeader[data-field="title"],
.MuiDataGrid-cell[data-field="title"] {
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
}

/* Zeilenstyling */
.MuiDataGrid-row {
  background-color: rgb(17, 24, 39) !important;
  height: 45px !important;
  max-height: 45px !important;
  min-height: 45px !important;
  border-bottom: 1px solid rgba(81, 81, 81, 0.5) !important;
  align-items: center !important;
}

.MuiDataGrid-row:hover {
  background-color: rgba(55, 65, 81, 0.7) !important;
}

.MuiDataGrid-row.Mui-selected {
  background-color: rgba(25, 118, 210, 0.3) !important;
  font-weight: 500 !important;
}

.MuiDataGrid-row.Mui-selected:hover {
  background-color: rgba(25, 118, 210, 0.4) !important;
}

/* Nur für Zelleninhalte Text abschneiden, nicht für Überschriften */
.MuiDataGrid-cell .MuiBox-root,
.MuiDataGrid-cell .MuiTypography-root {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.MuiDataGrid-cellContent {
  text-align: left !important;
  width: 100% !important;
}

/* Pagination */
.MuiTablePagination-root {
  color: rgb(209, 213, 219) !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0.75rem !important;
}

.MuiTablePagination-selectIcon {
  color: rgb(209, 213, 219) !important;
}

/* Angepasste Spaltenbreiten für vollständig lesbare Überschriften */
.MuiDataGrid-columnHeader[data-field="ansprechpartner"],
.MuiDataGrid-cell[data-field="ansprechpartner"] {
  min-width: 150px !important;
  width: 150px !important;
  max-width: 150px !important;
}

.MuiDataGrid-columnHeader[data-field="zugewiesenAn"],
.MuiDataGrid-cell[data-field="zugewiesenAn"] {
  min-width: 140px !important;
  width: 140px !important;
  max-width: 140px !important;
}

.MuiDataGrid-columnHeader[data-field="closeTicket"],
.MuiDataGrid-cell[data-field="closeTicket"] {
  min-width: 130px !important;
  width: 130px !important;
  max-width: 130px !important;
}

/* Optimierter Text bei Überlauf */
.MuiDataGrid-cell .MuiBox-root,
.MuiDataGrid-cell .MuiTypography-root,
.MuiDataGrid-columnHeaderTitle {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/* Scrollbars entfernen */
.MuiDataGrid-root .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-virtualScroller {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* IE and Edge */
}

/* Horizontalen Scrollbalken am unteren Bildschirmrand ebenfalls entfernen */
.MuiDataGrid-root .MuiDataGrid-horizontalScrollBar::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-horizontalScrollBar {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

/* Seitenschaltung-Navigationsbuttons verbergen */
.MuiTablePagination-actions {
  display: none !important;
}

