.split {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.gutter {
  background-color: rgb(24, 144, 255);
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
  width: 10px !important;
}

.split > div {
  height: 100%;
} 