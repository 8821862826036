.page-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header Styling */
.dashboard-header {
  background: linear-gradient(90deg, #1a237e 0%, #2563eb 100%);
  padding: 16px 24px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-stats {
  display: flex;
  gap: 16px;
}

.stat-item {
  text-align: center;
  padding: 0 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

/* Cards Container */
.dashboard-cards {
  background: rgba(0, 0, 0, 0.3);
  padding: 24px;
  border-radius: 0 0 8px 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.dashboard-card {
  background: white !important;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.dashboard-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Typography */
.dashboard-card .MuiTypography-subtitle1 {
  color: #64748b !important;
  font-size: 0.9rem !important;
  margin-bottom: 8px !important;
}

.dashboard-card .MuiTypography-h4 {
  color: #1e293b !important;
  font-weight: 600 !important;
}

.title {
  color: white;
  margin: 0;
}

/* Stats in Header */
.stat-number {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.stat-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
}
