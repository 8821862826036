.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  position: relative;
  overflow: hidden;
  background-color: #111827;
  color: #fff;
  border-top: 1px solid var(--divider);
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #111827;
  gap: 16px;
}

.message {
  max-width: 50% !important;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 8px;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: transparent !important;
}

.message.sent {
  margin-left: auto;
  margin-right: 10px;
  background-color: #1976d2 !important;
}

.message.received {
  margin-right: auto;
  margin-left: 10px;
  background-color: #2f3b4c !important;
}

.message-input-container {
  background: white;
  color: black !important;
  padding: 1rem;
  border-top: 1px solid #2A2F3E;
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  min-height: fit-content;
  max-height: 30%;
}

.message-input {
  flex: 1;
  background: #2A2F3E;
  border-radius: 24px;
  padding: 0.75rem 1rem;
  min-height: 45px;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #2A2F3E;
  color: white;
  transition: all 0.2s ease;
}

.message-input:focus {
  background: #2A2F3E;
  border-color: #00BFA5;
  box-shadow: 0 0 0 2px rgba(0, 191, 165, 0.1);
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.send-button {
  background: #00BFA5;
  color: white;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.send-button:hover {
  background: #00A693;
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Scrollbar Styling */
.messages-container::-webkit-scrollbar {
  width: 6px;
}

.messages-container::-webkit-scrollbar-track {
  background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
  background: #2A2F3E;
  border-radius: 3px;
}

/* Typing Indicator */
.typing-indicator {
  padding: 0.5rem 1rem;
  background: rgba(42, 47, 62, 0.3);
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  margin: 0.5rem 0;
}

.typing-dot {
  width: 4px;
  height: 4px;
  background: #00BFA5;
  border-radius: 50%;
  animation: typingAnimation 1.4s infinite ease-in-out;
}

.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typingAnimation {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}

/* Rich Text Editor Styles */
.message-content {
  color: inherit;
  font-size: 0.9375rem;
  line-height: 1.5;
}

.message-content p {
  margin: 0;
  font-size: 1.125rem;
}

.message.received .message-content {
  color: inherit !important;
}

.message.sent .message-content {
  color: inherit !important;
}


.ql-editor {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 1.6 !important;
  color: black !important;
  background: white !important;
  min-height: 200px !important;
  max-height: 400px !important;
}

.ql-editor p {
  margin-bottom: 0.5rem;
}

/* Code Block Styling */
.message-content pre,
.message-content code {
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  background: rgba(26, 35, 126, 0.1) !important;
  padding: 0.75rem;
  border-radius: 6px;
  overflow-x: auto;
  color: inherit !important;
}

/* Syntax Highlighting */
.syntax {
  color: inherit !important;
}

/* Ensure proper message formatting */
.message.received .message-content *,
.message.sent .message-content * {
  color: inherit !important;
}

/* Fix für Code-Blöcke in Nachrichten */
.message pre,
.message code {
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(26, 35, 126, 0.1) !important;
}

/* Quill Editor Container Anpassungen */
.message-input-container .quill {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Toolbar Buttons Hover Effect */
.ql-toolbar button:hover {
  color: #2563eb !important;
}

.ql-toolbar button.ql-active {
  color: #2563eb !important;
}

/* Emoji Picker Anpassungen */
.emoji-picker {
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.message {
  margin-top: 2px;
}

.message.first-of-group {
  margin-top: 8px;
}

/* Gruppierte Nachrichten haben andere Radius */
.message:not(.first-of-group) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.message .MuiTypography-caption {
  color: inherit !important;
  opacity: 0.7;
}

/* Editor Styles */
.message-input-container {
  background: white;
  color: black !important;
}

.ql-editor {
  color: black !important;
  background: white !important;
}

.ql-container {
  color: black !important;
  background: white !important;
}

/* Placeholder Text */
.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6) !important;
}

/* Toolbar Buttons */
.ql-toolbar button {
  color: black !important;
}

.ql-toolbar {
  background: white !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

/* Datumstrenner */
.date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  position: relative;
}

.date-separator::before,
.date-separator::after {
  content: '';
  flex: 1;
  height: 1px;
  background: transparent;
  margin: 0 1rem;
  border: none;
}

.date-label {
  background-color: #1F2937;
  color: #9CA3AF;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.875rem;
}

/* Seitenleiste für Chats */
.chat-sidebar {
  background: #1a1f2e; /* Dunkler Hintergrund wie im Screenshot */
  color: white;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

/* Chat-Liste Styling */
.chat-list {
  padding: 0;
}

/* Chat-Einträge */
.chat-list-item {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-list-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

/* Ticket Titel */
.chat-list-item .ticket-title {
  color: #ff9800; /* Orange für Ticket-Text */
  font-weight: 500;
  margin-bottom: 4px;
}

/* Ticket ID */
.chat-list-item .ticket-id {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

/* Aktiver Chat */
.chat-list-item.active {
  background: rgba(26, 35, 126, 0.3); /* Leicht bläulicher Hintergrund für aktiven Chat */
  border-left: 3px solid #1a237e;
}

/* Suchleiste anpassen */
.search-input {
  background: rgba(255, 255, 255, 0.1); /* Dunkler Hintergrund */
  color: white; /* Weiße Schriftfarbe */
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 8px 12px;
  margin: 16px;
  width: calc(100% - 32px);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5); /* Platzhalterfarbe */
}

/* Badge Styling */
.chat-badge {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  margin-right: 8px;
}

/* Grundlegendes Container-Styling */
.chat-list-container {
  background: #1a1f2e !important;
  color: white !important;
}

/* MUI List Container überschreiben */
.MuiList-root {
  background: #1a1f2e !important;
  color: white !important;
}

/* MUI List Item überschreiben */
.MuiListItem-root {
  background: #1a1f2e !important;
  color: white !important;
}

/* Ticket-Liste */
.ticket-list {
  background: #1a1f2e !important;
  color: white !important;
}

/* Ticket-Items */
.ticket-item {
  background: #1a1f2e !important;
  color: white !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Ticket ID Text */
.ticket-id {
  color: #ff9800 !important; /* Orange für die Ticket-ID */
}

/* Hover-Effekt */
.MuiListItem-root:hover {
  background: rgba(255, 255, 255, 0.05) !important;
}

/* Aktives Item */
.MuiListItem-root.Mui-selected {
  background: rgba(26, 35, 126, 0.3) !important;
  border-left: 3px solid #1a237e !important;
}

/* Ticket-Items mit Zeitstempel */
.ticket-item {
  background: #1a1f2e !important;
  color: white !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Alle Text-Elemente innerhalb der Ticket-Items */
.ticket-item * {
  color: white !important;
}

/* Zeitstempel und zusätzliche Informationen */
.MuiTypography-root.MuiTypography-caption,
.ticket-timestamp,
.ticket-info {
  color: rgba(255, 255, 255, 0.7) !important; /* Leicht transparentes Weiß für bessere Lesbarkeit */
}

/* Ticket ID bleibt orange */
.ticket-id {
  color: #ff9800 !important;
}

/* Ticket-Items mit ALLEN Text-Elementen */
.ticket-item,
.ticket-item p,
.ticket-item span,
.ticket-item div,
.ticket-item a,
.MuiListItemText-primary,
.MuiListItemText-secondary,
.MuiTypography-root,
.ticket-meta,
.ticket-details,
.ticket-email,
.ticket-contact,
.MuiListItemText-root * {
  color: white !important;
}

/* Zeitstempel und Meta-Informationen */
.MuiTypography-caption,
.ticket-timestamp,
.ticket-info,
.ticket-meta *,
.MuiListItemText-secondary {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* Ticket ID in Orange */
.ticket-id,
.ticket-number {
  color: #ff9800 !important;
}

/* Quill Editor Styles */
.quill {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-top: auto;
}

.ql-toolbar {
  border: none !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px 8px 0 0;
}

.ql-toolbar button {
  color: white !important;
}

.ql-toolbar button svg {
  filter: invert(1);
}

.ql-container {
  border: none !important;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0 0 8px 8px;
}

.ql-editor {
  color: black !important;
  min-height: 100px;
  max-height: 200px;
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6) !important;
}

.ql-editor {
  background-color: #374151 !important;
  color: #fff !important;
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important;
  overflow-y: auto !important;
  border-radius: 8px;
  padding-right: 100px !important;
}

.ql-toolbar {
  background-color: #1F2937 !important;
  border-bottom: 1px solid rgba(255,255,255,0.1) !important;
}

.ql-toolbar button {
  color: #fff !important;
}

.message-content {
  color: inherit;
  font-size: 0.9375rem;
  line-height: 1.5;
}

/* Weitere spezifische Styles... */

.unread-badge {
  background-color: #2563eb;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  position: absolute;
  right: 16px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-list-item {
  position: relative;
  transition: background-color 0.2s ease;
}

.message-list-item:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.message-list-item.unread {
  background-color: rgba(37, 99, 235, 0.1);
}

.custom-quill {
  color: white;
}

.custom-quill .ql-editor {
  color: white;
}

.custom-quill .ql-editor::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Toolbar Icons weiß machen */
.custom-quill .ql-toolbar .ql-stroke {
  stroke: white;
}

.custom-quill .ql-toolbar .ql-fill {
  fill: white;
}

.custom-quill .ql-toolbar .ql-picker {
  color: white;
}

.custom-quill .ql-toolbar button:hover .ql-stroke {
  stroke: #2563eb;
}

.custom-quill .ql-toolbar button:hover .ql-fill {
  fill: #2563eb;
}

.chat-attachments {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 16px;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.attachment-preview {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  max-width: 200px;
  transition: background-color 0.2s;
}

.attachment-preview:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.attachment-preview .file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
  color: white;
  font-size: 0.875rem;
}

.attachment-preview .remove-button {
  color: rgba(255, 255, 255, 0.7);
  padding: 4px;
  transition: color 0.2s;
}

.attachment-preview .remove-button:hover {
  color: white;
}

/* Anpassungen für den Nachrichtenbereich */
.message-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.message-attachments {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.message-attachment {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  cursor: pointer;
  border: none;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
}

.message-attachment:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.message-attachment .attachment-name {
  margin-left: 8px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
}

/* Anpassungen für den Editor-Bereich */
.ql-container {
  position: relative;
}

.attachment-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 2;
}

/* Rich Text Editor Anpassungen */
.ql-toolbar {
  background-color: #1F2937 !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-container {
  background-color: #374151 !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-top: none !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 100px;
}

.ql-editor {
  color: #fff !important;
  min-height: 100px;
  max-height: 200px;
  font-size: 0.875rem;
}

.ql-editor.ql-blank::before {
  color: rgba(255, 255, 255, 0.6) !important;
  font-style: normal !important;
}

/* Quill Toolbar Buttons */
.ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.7) !important;
}

.ql-snow .ql-fill {
  fill: rgba(255, 255, 255, 0.7) !important;
}

.ql-snow .ql-picker {
  color: rgba(255, 255, 255, 0.7) !important;
}

.ql-snow .ql-picker-options {
  background-color: #1F2937 !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.ql-snow .ql-picker-item {
  color: rgba(255, 255, 255, 0.7) !important;
}

.ql-snow .ql-active {
  color: #3b82f6 !important;
}

.ql-snow .ql-active .ql-stroke {
  stroke: #3b82f6 !important;
}

.ql-snow .ql-active .ql-fill {
  fill: #3b82f6 !important;
}

/* Basis-Container für Nachrichten */
.message-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 1rem;
}

/* Header mit Absender-Info */
.message-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.sender-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
}

/* Nachrichteninhalt */
.message-content {
  color: #fff;
  white-space: pre-wrap;
  word-break: break-word;
}

/* Dateianhänge */
.attachments-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.attachment-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
}

.attachment-item:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

/* Styles für eingebettete Bilder */
.ql-editor img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem 0;
  border-radius: 4px;
}

/* Styles für den Bild-Upload Button */
.ql-snow .ql-toolbar button.ql-image {
  color: white !important;
}

.ql-snow .ql-toolbar button.ql-image svg {
  filter: invert(1);
}

/* Anpassungen für die Bildanzeige in Nachrichten */
.message-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 8px 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

/* Hover-Effekt für Bilder */
.message-content img:hover {
  cursor: pointer;
  opacity: 0.9;
}

.ql-container {
  height: 100px !important;
}

.ql-editor {
  background-color: #374151 !important;
  color: #fff !important;
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important;
  overflow-y: auto !important;
  border-radius: 8px;
  padding-right: 100px !important;
}

.custom-quill {
  height: 260px !important;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ql-toolbar {
  flex-shrink: 0;
}

/* Styling für den Button-Bereich */
.message-actions {
  display: flex;
  gap: 8px;
  padding: 8px;
  background-color: #1a1f2e;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* 4. CSS-Optimierungen */
.message-content img {
  content-visibility: auto; /* Verbessert Rendering-Performance */
  contain: content; /* Reduziert Layout-Berechnungen */
}

.ql-editor {
  will-change: transform; /* Optimiert Scrolling */
  transform: translateZ(0); /* Hardware-Beschleunigung */
}

/* Entfernen Sie diese Styles, die die Linien erzeugen */
.message::after,
.message::before,
.message-user-info::after,
.message-timestamp::before {
  display: none;
}

/* Nachrichtenanhänge ohne Linien */
.message-attachment {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  cursor: pointer;
  border: none;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
}

.attachment-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
}

/* Datum-Trennzeichen ohne Linien */
.date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  position: relative;
}

.date-separator::before,
.date-separator::after {
  content: '';
  flex: 1;
  height: 1px;
  background: transparent;
  margin: 0 1rem;
  border: none;
}

/* Bessere visuelle Trennung der Absender */
.message-user-info {
  margin-bottom: 6px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  font-size: 0.9rem;
}
