.page-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: left 0.3s ease;
}

.page-container.sidebar-expanded {
  left: 250px; /* Breite der ausgeklappten Sidebar */
}

.form-paper {
  width: 100% !important;
  height: auto !important;
  min-height: calc(100vh - 48px) !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  background: rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.selects-container .MuiFormControl-root {
  flex: 1;
}

/* Dateiupload-Bereich */
.file-upload-section {
  width: 100%;
}

/* Button-Container */
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: auto;
  padding-top: 24px;
}

/* Titel */
.title {
  margin-bottom: 24px !important;
  color: white !important;
  font-size: 1.5rem !important;
}

/* Input-Felder stylen */
.MuiTextField-root .MuiOutlinedInput-root,
.MuiSelect-root .MuiOutlinedInput-root,
.MuiTextField-root textarea {
  background-color: rgba(13, 17, 23, 0.7) !important;
  border-radius: 8px;
  color: white !important;
}

/* Label-Farbe */
.MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* Placeholder-Farbe */
.MuiInputBase-input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

/* Outline-Farbe */
.MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

/* Dropdown-Menü Text */
.MuiSelect-select {
  color: white !important;
}

/* Button-Styles aktualisiert */
.MuiButton-root {
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 8px 24px !important;
}

/* Hauptbutton (Ticket erstellen) */
.MuiButton-contained {
  background-color: #2563eb !important;
  color: white !important;
}

.MuiButton-contained:hover {
  background-color: #1d4ed8 !important;
}

/* Sekundärer Button (Zurücksetzen) */
.MuiButton-outlined {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  color: white !important;
  background-color: transparent !important;
}

.MuiButton-outlined:hover {
  border-color: white !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
}

/* Datei-Upload Button */
.file-button.MuiButton-outlined {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  color: white !important;
  background-color: transparent !important;
}

.file-button.MuiButton-outlined:hover {
  border-color: white !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.error-message {
  margin-bottom: 16px;
  background-color: #ffebee;
  padding: 16px;
  border-radius: 4px;
  color: #d32f2f;
}

.MuiTypography-root {
  color: white;
}

.MuiDivider-root {
  background-color: rgba(255, 255, 255, 0.12);
}

.MuiMenuItem-root {
  color: #1a1f2e;
}

/* Button-Container */
.MuiBox-root {
  background: transparent !important;
}

/* Falls nötig, spezifischer für die Button-Container */
form .MuiBox-root {
  background: transparent !important;
}

/* Spezifisch für das Beschreibungsfeld */
.MuiTextField-root textarea {
  background-color: rgba(13, 17, 23, 0.7) !important;
  border-radius: 8px;
  color: white !important;
}

/* Entfernt den weißen Hintergrund vom Container */
.MuiTextField-root .MuiOutlinedInput-root {
  background-color: transparent !important;
}

/* Anpassung der Outline-Farbe */
.MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

/* Beschreibungsfeld-Styling */
.description-field {
  min-height: 400px !important;
  width: 100% !important;
}

.description-field .MuiInputBase-root {
  min-height: 400px !important;
  width: 100% !important;
}

.description-field textarea {
  min-height: 400px !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  padding: 16px !important;
  width: 100% !important;
}

/* Grid-Container für bessere Ausnutzung der Breite */
.form-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Größere Eingabefelder */
.MuiTextField-root {
  width: 100% !important;
}

/* Extra großes Beschreibungsfeld */
.description-field {
  min-height: 400px !important;
}

.description-field .MuiInputBase-root {
  min-height: 400px !important;
}

.description-field textarea {
  min-height: 400px !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  padding: 16px !important;
}

/* Anpassungen für die Select-Felder */
.MuiFormControl-root {
  width: 100% !important;
}

/* Dateianhang-Bereich */
.file-upload-section {
  width: 100%;
  margin-top: 24px;
}

/* Button-Container */
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
  width: 100%;
}

/* Responsive Anpassungen */
@media (min-width: 1200px) {
  .form-paper {
    padding: 48px;
  }
}

.description-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.file-list {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 8px;
}

.file-list-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.file-list-item:last-child {
  border-bottom: none;
}

.description-field .MuiInputBase-input {
  font-family: monospace !important;
  white-space: pre-wrap !important;
}

.description-field .file-list-section {
  color: rgba(255, 255, 255, 0.7);
  border-top: 1px dashed rgba(255, 255, 255, 0.3);
  margin-top: 16px;
  padding-top: 8px;
}

.file-preview {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.file-preview:hover {
  border-color: rgba(255, 255, 255, 0.2);
}

.file-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-size {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.75rem;
}

.description-editor {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  min-height: 500px;
  background-color: #1f2937;
}

.description-editor .ql-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto !important;
  background-color: #1f2937;
}

.ql-editor {
  flex: 1;
  min-height: 100px !important;
  max-height: none !important;
  height: auto !important;
  background-color: #1f2937;
  color: white;
  font-size: 16px;
  line-height: 1.5;
}

.description-editor .ql-toolbar {
  background-color: rgba(30, 41, 59, 0.8);
  border-color: rgba(255, 255, 255, 0.1) !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.description-editor.has-files {
  min-height: 300px;
}

.file-preview-container {
  margin-top: 16px;
  background-color: rgba(30, 41, 59, 0.8);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.ql-container {
  height: auto !important;
  min-height: 100px !important;
}

.ql-container.ql-snow {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  background-color: #1f2937 !important;
}

.ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.7) !important;
}

.ql-snow .ql-fill {
  fill: rgba(255, 255, 255, 0.7) !important;
}

.ql-snow .ql-picker {
  color: rgba(255, 255, 255, 0.7) !important;
}

.ql-snow .ql-picker-options {
  background-color: #1f2937 !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.ql-snow .ql-picker-item {
  color: white !important;
}

/* Media Queries für verschiedene Bildschirmgrößen */
@media (max-width: 600px) {
  .form-paper {
    padding: 16px !important;
  }

  .description-field {
    min-height: 200px !important;
  }

  .ql-editor {
    min-height: 150px !important;
    font-size: 14px !important;
  }
}

@media (max-width: 480px) {
  .page-container {
    padding: 8px;
  }

  .form-paper {
    padding: 12px !important;
  }

  .ql-toolbar {
    flex-wrap: wrap;
  }

  .file-preview-container {
    margin-top: 8px;
  }
}

.create-ticket-container {
  width: 1200px; /* Basis-Breite */
  margin: 0 auto;
  transform-origin: top center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.form-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #1f2937;
  border-radius: 8px;
  height: fit-content;
}

.description-editor {
  min-height: 400px !important;
  height: 400px !important;
}

.ql-container {
  height: calc(400px - 42px) !important; /* 42px ist die Höhe der Toolbar */
}

.ql-editor {
  height: 100% !important;
}

