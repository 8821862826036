.page-container {
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
  }
  
  .form-paper {
    padding: 32px;
    margin-top: 24px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(10px) !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  }
  
  .title {
    color: white;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
  }
  
  .MuiTextField-root .MuiOutlinedInput-root,
  .MuiSelect-root .MuiOutlinedInput-root,
  .MuiTextField-root textarea {
    background-color: rgba(13, 17, 23, 0.7) !important;
    border-radius: 8px;
    color: white !important;
  }
  
  .MuiInputLabel-root {
    color: rgba(255, 255, 255, 0.7) !important;
  }
  
  .MuiInputBase-input::placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.2) !important;
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.3) !important;
  }
  
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
  
  .MuiSelect-select {
    color: white !important;
  }
  
  .MuiButton-root {
    border-radius: 8px !important;
    text-transform: none !important;
    padding: 8px 24px !important;
  }
  
  .MuiButton-contained {
    background-color: #2563eb !important;
    color: white !important;
  }
  
  .MuiButton-contained:hover {
    background-color: #1d4ed8 !important;
  }
  
  .MuiButton-outlined {
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    color: white !important;
    background-color: transparent !important;
  }
  
  .MuiButton-outlined:hover {
    border-color: white !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
  
  .file-button.MuiButton-outlined {
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    color: white !important;
    background-color: transparent !important;
  }
  
  .file-button.MuiButton-outlined:hover {
    border-color: white !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
  
  .error-message {
    margin-bottom: 16px;
    background-color: #ffebee;
    padding: 16px;
    border-radius: 4px;
    color: #d32f2f;
  }
  
  .MuiTypography-root {
    color: white;
  }
  
  .MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.12);
  }
  
  .MuiMenuItem-root {
    color: #1a1f2e;
  }
  
  .MuiBox-root {
    background: transparent !important;
  }
  
  form .MuiBox-root {
    background: transparent !important;
  }
  
  .MuiTextField-root textarea {
    background-color: rgba(13, 17, 23, 0.7) !important;
    border-radius: 8px;
    color: white !important;
  }
  
  .MuiTextField-root .MuiOutlinedInput-root {
    background-color: transparent !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.3) !important;
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
  
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.7) !important;
  }