.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a1a 0%, #2563eb 100%);
  padding: 20px;
}

.login-paper {
  padding: 32px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1) !important; /* Transparenter Hintergrund */
  backdrop-filter: blur(10px) !important; /* Glaseffekt */
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

/* Textfarben für bessere Lesbarkeit */
.MuiTypography-root {
  color: white !important;
}

/* Input-Felder stylen */
.MuiTextField-root {
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.05) !important;
  border-radius: 8px;
}

.MuiInputBase-input {
  color: white !important;
}

.MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.7) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

/* Hover-Effekte */
.MuiTextField-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

/* Fokus-Effekte */
.MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

