.ticket-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  font-size: 0.8rem;
}

.ticket-content {
  display: flex;
  flex: 1;
  min-height: 0;
  overflow: hidden;
}

.ticket-info {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  min-height: 0;
}

.chat-section {
  flex: 1;
  min-width: 220px;
  max-width: 400px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
}

/* Anpassung für den Fall, dass der Chat geschlossen ist */
.ticket-info.full-width {
  flex: 1;
}

.ticket-header-card {
  background: linear-gradient(135deg, #ffffff 0%, #f8fafc 100%) !important;
  border-radius: 8px !important;
  padding: 0.75rem !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid rgba(226, 232, 240, 0.8) !important;
  margin-bottom: 0.75rem;
  flex-shrink: 0;
}

.ticket-header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ticket-title-section {
  flex: 1;
}

.ticket-title {
  font-size: 1rem !important;
  color: #ffffff !important;
  margin-bottom: 0.4rem !important;
}

.ticket-badges {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.ticket-chip {
  border-radius: 8px !important;
  font-weight: 500 !important;
  height: 32px !important;
}

.time-chip {
  background-color: rgba(99, 102, 241, 0.1) !important;
  color: #4f46e5 !important;
}

.priority-chip-high {
  background-color: rgba(220, 38, 38, 0.1) !important;
  color: #dc2626 !important;
}

.priority-chip-medium {
  background-color: rgba(245, 158, 11, 0.1) !important;
  color: #f59e0b !important;
}

.priority-chip-low {
  background-color: rgba(16, 185, 129, 0.1) !important;
  color: #10b981 !important;
}

.status-chip-open {
  background-color: rgba(16, 185, 129, 0.1) !important;
  color: #10b981 !important;
}

.status-chip-closed {
  background-color: rgba(220, 38, 38, 0.1) !important;
  color: #dc2626 !important;
}

.chat-toggle-button {
  background-color: #f8fafc !important;
  border: 1px solid #e2e8f0 !important;
  transition: all 0.2s ease !important;
}

.chat-toggle-button:hover {
  background-color: #f1f5f9 !important;
  transform: translateY(-1px);
}

.chat-toggle-button.active {
  background-color: #3b82f6 !important;
  color: white !important;
}

.ticket-details-card {
  background: white !important;
  border-radius: 16px !important;
  padding: 1.5rem !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid rgba(226, 232, 240, 0.8) !important;
}

.info-section {
  margin-bottom: 2rem;
}

.section-title {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  color: #1e293b !important;
  margin-bottom: 1.5rem !important;
  letter-spacing: -0.025em;
}

.info-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.info-item:hover {
  background: #f1f5f9;
}

.info-icon {
  color: #64748b !important;
}

.info-label {
  font-size: 0.875rem !important;
  color: #64748b !important;
  margin-bottom: 0.25rem !important;
}

.info-value {
  font-size: 1rem !important;
  color: #1e293b !important;
  font-weight: 500 !important;
}

.info-divider {
  margin: 0.5rem 0 !important;
  background-color: #e2e8f0 !important;
}

.description-text {
  font-size: 1.125rem !important;
  line-height: 1.75 !important;
  color: #475569 !important;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 12px;
}

.chat-card {
  height: 100% !important;
  border-radius: 16px !important;
  overflow: hidden !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid rgba(226, 232, 240, 0.8) !important;
}

@media (max-width: 768px) {
  .ticket-container {
    padding: 1rem;
  }
  
  .ticket-badges {
    gap: 0.5rem;
  }
  
  .ticket-title {
    font-size: 1.25rem !important;
  }
}

.ticket-info-box.MuiPaper-root > .MuiGrid-container {
  display: flex !important;
  flex-wrap: nowrap !important;
  gap: 1rem;
}

.ticket-info-box.MuiPaper-root > .MuiGrid-container > .MuiGrid-item {
  flex: 1 !important;
  width: 50% !important;
  max-width: 50% !important;
}

.ticket-info-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Stark spezifische Regeln für einheitliche Info-Zeilen mit höherer Priorität */
.ticket-container .info-row,
.ticket-info-box .info-row,
div.info-row {
  display: flex !important;
  align-items: center !important;
  padding: 8px 12px !important;
  gap: 12px !important;
  background: rgba(30, 41, 59, 0.5) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 6px !important;
  transition: all 0.3s ease !important;
  margin-bottom: 10px !important;
  height: 42px !important; /* Feste Höhe erzwingen */
  min-height: 42px !important;
  max-height: 42px !important;
  box-sizing: border-box !important;
  width: 100% !important;
}

/* Spezifische Regel für Icons */
.ticket-container .info-row .info-icon,
.ticket-info-box .info-row .info-icon,
.info-row > .MuiSvgIcon-root,
div.info-row svg {
  color: #60a5fa !important;
  min-width: 24px !important;
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px !important;
  flex-shrink: 0 !important;
}

/* Spezifische Regel für Text */
.ticket-container .info-row .info-text,
.ticket-info-box .info-row .info-text,
.info-row > .MuiTypography-root,
div.info-row p {
  font-size: 0.8rem !important;
  color: #f8fafc !important;
  flex: 1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  line-height: 1.2 !important;
  margin: 0 !important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

/* Einheitlicher Titel-Bereich */
.ticket-info-box > div > .MuiBox-root:first-of-type {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 16px !important;
  padding: 14px !important;
  background-color: rgba(30, 41, 59, 0.5) !important;
  border-radius: 6px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  height: 60px !important;
  min-height: 60px !important;
  box-sizing: border-box !important;
}

/* Einheitliche Containergröße für die beiden Spalten */
.ticket-info-box .MuiGrid-container {
  display: flex !important;
  flex-wrap: wrap !important;
  width: 100% !important;
  margin: 0 !important;
}

.ticket-info-box .MuiGrid-item {
  flex: 0 0 50% !important;
  max-width: 50% !important;
  padding: 0 10px !important;
  box-sizing: border-box !important;
}

/* Gleich große Container für die Listen */
.ticket-info-box .ticket-info-list {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}

.ticket-container .MuiButton-root {
  text-transform: none;
  border-radius: 8px;
  padding: 2px 10px !important;
  font-size: 0.75rem !important;
  min-height: 30px !important;
}

.ticket-container .MuiButton-contained {
  background-color: #3b82f6;
}

.ticket-container .MuiButton-contained:hover {
  background-color: #2563eb;
}

.ticket-container .MuiButton-contained.Mui-disabled {
  background-color: rgba(59, 130, 246, 0.5);
}
 